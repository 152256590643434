import { BrowserModule, HAMMER_GESTURE_CONFIG } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule } from '@angular/core';


import { MatSidenavModule } from '@angular/material/sidenav';
import { GestureConfig, MatDialogModule } from '@angular/material';

import 'hammerjs';

/* HTTP Module and HTTP_Interceptors for making http calls */
import { HttpClientModule, HttpClientXsrfModule } from '@angular/common/http';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { JwtModule } from '@auth0/angular-jwt';

/*Routing module*/
import { AppRoutingModule } from '@app/app-routing.module';
import { AppComponent } from '@app/app.component';

import { TokenAuth } from '@app/core/auth/auth.interceptor';
import { AuthService } from '@app/core/auth/auth.service';

import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '@env/environment';
import { FdhdrPipe } from './fdhdr.pipe';
import { IcolorPipe } from './icolor.pipe';
import { IlMessageDialogComponent } from './il-message-dialog/il-message-dialog.component';
import { ErrorComponent } from './error/error/error.component';

/**
 * The bootstrapper module
 */
@NgModule({
  declarations: [AppComponent, FdhdrPipe, IcolorPipe, IlMessageDialogComponent, ErrorComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    HttpClientXsrfModule,
    JwtModule,
    AppRoutingModule,
    MatSidenavModule,
    MatDialogModule,
    ServiceWorkerModule.register('./ngsw-worker.js', {
      enabled: environment.production
    })
  ],
  entryComponents: [IlMessageDialogComponent],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenAuth,
      multi: true
    },
    { provide: HAMMER_GESTURE_CONFIG, useClass: GestureConfig },
    AuthService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }

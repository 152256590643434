import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'fdhdr'
})
export class FdhdrPipe implements PipeTransform {

  transform(value: any): any {
    if (typeof value !== 'undefined')
      return value === 2 ? 'C4'
        : value === 3 ? 'C2'
          : value === 4 ? 'E/P'
            : value === 5 ? 'C3'
              : value === 6 ? 'DCK'
                : value === 7 ? 'Down'
                  : 'C/O';
    else {
      return null;
    }
  }

}

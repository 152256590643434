import { Component, OnInit, ViewEncapsulation, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';

@Component({
  selector: 'app-il-message-dialog',
  templateUrl: './il-message-dialog.component.html',
  styleUrls: ['./il-message-dialog.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class IlMessageDialogComponent implements OnInit {
  constructor(public dialogRef: MatDialogRef<IlMessageDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,

  ) { }
  ngOnInit() {

  }

  /* Method to close the popup */
  onNoClick(): void {
    this.dialogRef.close();
  }

}

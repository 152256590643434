import { Injectable } from '@angular/core';
import { JwtHelperService } from '@auth0/angular-jwt';
const helper = new JwtHelperService();

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  constructor() { }

  /* Method which returns jwt token */
  public getToken(): string {
    if (localStorage.getItem('user')) {
      return JSON.parse(localStorage.getItem('user'))['token'];
    }
  }

  public isSessionExpired(): boolean {
    const token = this.getToken();
    // commented below as we didn't implement token validity
    return token ? helper.isTokenExpired(token) : true;

    // remove below line if we implement token validity
    //return token ? true : false;
  }

  /* Method returns decoded token */
  public getLoggedUserPermissions(): object {
    const token = this.getToken();

    const decoded = helper.decodeToken(token);

    return decoded['permission'];
  }

  /* Method which returns the role of logged user */
  public getLoggedUserRole(): string {
    const token = this.getToken();

    const decoded = helper.decodeToken(token);
    return decoded['role'];
  }

  /* Method which returns the first name and last name of logged user */
  public getUsername() {
    const token = this.getToken();

    const decoded = helper.decodeToken(token);
    return `${decoded['first_name']} ${decoded['last_name']}`;
  }
}

import { Injectable } from '@angular/core';
import { formatDate } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { environment } from '@env/environment';

const baseUrl = environment['baseUrl'];

@Injectable({
  providedIn: 'root'
})
export class UtilsService {
  constructor(private http: HttpClient) { }

  /* Method to get inferential logic message for selected furnace */
  getILMessage(equipment) {
    return this.http.get(`${baseUrl}inferential-logic/${equipment}`)
      .pipe(map((response: any) => {
        return response
      }, (error) => {
        console.log(error);
      }
      ));
  }

  /* Method to get licence validity */
  getLicenceValidity() {
    return this.http.get(`${baseUrl}popup-notification`)
      .pipe(map((response: any) => {
        return response
      }, (error) => {
        console.log(error);
      }
      ));
  }

  /* Method to add comment for external targets, accesible only to admin */
  addComment(body): Observable<any> {
    const b = { comment: body['comment'] };

    return this.http
      .put(`${baseUrl}update/external-targets?equipment=${body['equipment']}&tag_name=${body['tag']}`, b).pipe(map((response: any) => {
        return response;
      },
        err => {
          return err;
        }));
  }

  /* Method to update configuration parameters for selected equipment */
  updateConfiguration(body, isall?: string): Observable<any> {

    const e = body['equipment'];
    const f = body['feed'];
    const t = body['type'];

    const { equipment, feed, type, ...b } = body;

    // const b = (({ external_targets, performace_tags }) =>
    //   ({ external_targets, performace_tags }))(body);

    return this.http.put(`${baseUrl}configuration/lbt/parameters/equipments/update?is_active=${t}&feed=${f}&equipment=${e}`, b)
      .pipe(map((response: any) => {
        return response
      }, (error) => {
        console.log(error);
      }
      ));
  }

  /* Method to get list of config parameters for the selected equipment and feed type */
  getConfiguration(type, equipment, feed): Observable<any> {
    return this.http.get(`${baseUrl}configuration/lbt/parameters/equipments?is_active=${type}&feed=${feed}&equipment=${equipment}`)
      .pipe(map((response: any) => {
        return response
      }, (error) => {
        console.log(error);
      }
      ));
  }

  /* Method to get graph data for selected tag
   * Created this method here to avoid multiple imports of apicallsservice for
   * different feratures
   */
  getGraphData(url, equipment, tag, filter): Observable<any> {
    const isExctracted = equipment['equipment'].length < 7 ? true : false;
    const e = isExctracted ? equipment['equipment'] : this.extractEquipmentTag(
      equipment['equipment']
    )
    return this.http
      .get(
        `${baseUrl}feature/${url}/${equipment['unit']}/${equipment['console']
        }/${e}?tag_name=${tag}&start_date=${filter['startDate']}&end_date=${filter['endDate']
        }`
      )
      .pipe(
        map(
          (response: any) => {
            return response;
          },
          error => {
            console.log(error);
          }
        )
      );
  }


  /* Method to get FDHDR tags, currently using hot 
    console 1 api, can be merged in consoles(side menu) api */
  getFdhdr(): Observable<any> {
    return this.http
      .get(
        `${baseUrl}dashboard/console/feature/Unit 1592/Hot Console 1`
      )
      .pipe(
        map(
          (response: any) => {
            return response;
          },
          error => {
            console.log(error);
          }
        )
      );
  }

  /* Method which returns data for scatter chart */
  formScatterChartData(data, keys, isDate?: boolean) {
    return data[keys[1]]
      .map((e, i) => Object.assign({},
        ...keys.map((k) => ({
          [k]: (isDate && k === 'x_axis')
            ? this.convertDateToCST(data[k][i]) : data[k][i]
        }))));
  }

  /* Sort object */
  sortObject(o) {
    return Object.keys(o).sort().reduce((r, k) => (r[k] = o[k], r), {});
  }

  /* Method which convert title to lower case splitted by - */
  formRoute(str) {
    const route = str
      .toLowerCase()
      .replace(/ /g, '-')
      .replace(/(?: |\b)(\w)/g, (key, p1) => {
        return key.toLowerCase();
      });
    return route;
  }

  /* Method which extracts equipment tag name from
   * combination of equipment name and equipment tag
   */
  extractEquipmentTag(e) {
    return e.substring(e.lastIndexOf('(') + 1, e.lastIndexOf(')'));
  }

  /* Method which extracts equipment tag name from
   * combination of equipment name and equipment tag
   */
  extractEquipmentName(e) {
    return e.substring(0, e.indexOf('(') - 1);
  }

  /* Method to get list of units/consoles/equipments */
  getAllConsoles(): Observable<any> {
    return this.http.get(`${baseUrl}consoles`).pipe(
      map(
        (response: any) => {
          return response;
        },
        error => {
          console.log(error);
        }
      )
    );
  }

  /* Merge x and y values of graphs into chunks */
  formGraphData(x, y, isScatter?: boolean) {
    return x.reduce((e, key, i) => e.push([isScatter ? key : this.convertDateToCST(key), y[i]]) && e, []);
  }

  /* Method to form array of json to plot graphs using amcharts */
  formAmChartGraph(x, y, flags) {
    return x.map((date, i) => ({ date: this.convertDateToCST(date), value: y[i], color: this.setColor(flags[i]) }));
  }

  /* Method to form array of json to plot graphs of multi line chart using amcharts  */
  formAmChartMultiLineGraph(x, y, p, flags) {
    return x.map((date, i) => ({ date: this.convertDateToCST(date), value: y[i], predicted: p[i], color: this.setColor(flags[i]) }));
  }

  /* Method which returns array of json to plot a4 graph using amcharts */
  formAmChartsEhGraph(data, keys) {

    return data[keys[0]].map((e, i) => Object.assign({}, ...keys.map((k) => ({ [k]: k === 'x_axis' ? this.convertDateToCST(data[k][i]) : data[k][i] }))));
  }

  /* Method to form graph id */
  formGraphId(tag) {
    /* const random = Math.random().toString(36)
    .substring(2, 15) + Math.random().toString(36).substring(2, 15); */

    return `${tag.replace(/ /g, '')}`;
  }

  /* Method to set the alert flag */
  setColor(flag) {
    return flag === 4 ? '#FF0000' : flag === 3 ? '#FFC813' : flag === 2 ? '#0667BB' : '#7FBB06';
  }

  /* Method to convert date to CST */
  convertDateToCST(d) {
    return typeof d !== 'undefined' ? `${new Date(d).toLocaleString("en-US", { timeZone: 'America/Chicago' })}` : null;
  }

  /* Set scatter chart dot color */
  getScatterColor(flag) {
    return flag === 2
      ? '#0088FF'
      : flag === 3
        ? '#FFC813'
        : flag === 4
          ? '#FF0000'
          : '#7FBB06';
  }

  // Method to form console component class name for console graphical layout
  formCComponent(u) {
    const c = decodeURI(u.split('/')[3]);
    return `${c.replace(/ /g, '')}Component`;
  }

  // Method to form unit component class name for unit graphical layout
  formUComponent(u) {
    const unit = decodeURI(u.split('/')[2]);
    return `${unit.replace(/ /g, '')}Component`;
  }

  // Method to form equipment component class name for unit graphical layout
  formEComponent(u) {
    const equipment = decodeURI(u.split('/')[4]);

    const e = this.extractEquipmentName(equipment);

    const searchValue = e.includes(' ') ? / /g : /-/g;

    const eName = e.replace(searchValue, '').replace('-', '').replace(',', '').replace('%2F', '');

    return e === 'Furnace' ? `${eName}${this.addTagNameToFurnace(equipment)}Component` : `${eName}Component`;
  }

  // Method to form equipment labels component class name for unit graphical layout
  formELComponent(u) {
    const equipment = decodeURI(u.split('/')[4]);

    const e = this.extractEquipmentName(equipment);

    const searchValue = e.includes(' ') ? / /g : /-/g;

    const eName = e.replace(searchValue, '').replace('-', '').replace(',', '').replace('%2F', '');


    return e === 'Furnace' ? `${eName}${this.addTagNameToFurnace(equipment)}LabelsComponent` : `${eName}LabelsComponent`;
  }

  /* Method to form furnace name including tagname */
  addTagNameToFurnace(e) {

    return e.substring(13, 15);
  }


  /* Method which returns error tags for Instrument Drift */
  getIDErrorTags(u, data) {
    // extract equipment name
    const equipment = this.extractEquipmentName(u);
    if (equipment !== 'Furnace') {
      return data;
    } else {
      const calcResults = [];

      // filter errors other than 'AK11'
      const otherErrors = data.filter(
        a =>
          a['tag_name'].substring(0, 4) !== 'AK11'
      );

      // filter 'AK11' errors
      const errors = data.filter(
        a =>
          a['tag_name'].substring(0, 4) === 'AK11'
      );

      // form CALC tags based on the errors data
      errors.forEach(e => {
        if (calcResults.length === 0) {
          // push first element into calcResults array
          calcResults.push(e);
        } else {
          // check if item exists in calcResults
          const index = calcResults.findIndex(
            it => it['tag_name'].charAt(7) === e['tag_name'].charAt(7)
          );

          // if the item doesnot exist push into calcResults, replacing the tag name
          if (index === -1) {
            calcResults.push({
              ...e,
              ...{
                tag_name: `${e['tag_name'].slice(0, -8)}CALC`
              }
            });
          } else {
            // if the item exist, replace the tag name and replace with max criticality
            calcResults[index]['alert_flag'] =
              e['alert_flag'] > calcResults[index]['alert_flag']
                ? e['alert_flag']
                : calcResults[index]['alert_flag'];

            calcResults[index]['tag_name'] = `${e['tag_name'].slice(
              0,
              -8
            )}CALC`;
          }
        }
      });
      // concat calcResults and other results
      return [...calcResults, ...otherErrors];
    }
  }

  /* Method which return ranges for coloring the graph */
  getRangesForGraphs(graph, y) {
    // create object to group based on alert flags
    const flagsGroup = { red: [], blue: [], green: [], yellow: [] };

    // group based on criticality only if graphdata exists
    if (graph.length > 0) {
      graph.forEach(g => {
        // check if alert tags are in arrray
        if (typeof g['alert_flag'] === 'object') {
          // filter and push results into grouped object
          g['alert_flag'].forEach((e, i) =>
            e === 4
              ? flagsGroup['red'].push(g[y][i])
              : e === 3
                ? flagsGroup['yellow'].push(g[y][i])
                : e === 2
                  ? flagsGroup['blue'].push(g[y][i])
                  : flagsGroup['green'].push(g[y][i])
          );
        }
      });
    }



    // calculate red min
    const rmin =
      flagsGroup['red'].length > 0
        ? Math.min.apply(
          Math,
          flagsGroup['red'].map(e => {
            return e;
          })
        )
        : 0;

    // calculate red max
    const rmax =
      flagsGroup['red'].length > 0
        ? Math.max.apply(
          Math,
          flagsGroup['red'].map(e => {
            return e;
          })
        )
        : 0;

    // calculate yellow min
    const ymin =
      flagsGroup['yellow'].length > 0
        ? Math.min.apply(
          Math,
          flagsGroup['yellow'].map(e => {
            return e;
          })
        )
        : 0;

    // calculate yellow max
    const ymax =
      flagsGroup['yellow'].length > 0
        ? Math.max.apply(
          Math,
          flagsGroup['yellow'].map(e => {
            return e;
          })
        )
        : 0;

    // calculate green min
    const gmin =
      flagsGroup['green'].length > 0
        ? Math.min.apply(
          Math,
          flagsGroup['green'].map(e => {
            return e;
          })
        )
        : 0;

    // calculate green max
    const gmax =
      flagsGroup['green'].length > 0
        ? Math.max.apply(
          Math,
          flagsGroup['green'].map(e => {
            return e;
          })
        )
        : 0;

    // calculate blue min
    const bmin =
      flagsGroup['blue'].length > 0
        ? Math.min.apply(
          Math,
          flagsGroup['blue'].map(e => {
            return e;
          })
        )
        : 0;

    // calculate blue max
    const bmax =
      flagsGroup['blue'].length > 0
        ? Math.max.apply(
          Math,
          flagsGroup['blue'].map(e => {
            return e;
          })
        )
        : 0;


    return {
      rMin: rmin,
      rMax: rmax,
      yMin: ymin,
      yMax: ymax,
      gMin: gmin,
      gMax: gmax,
      bMax: bmax,
      bMin: bmin
    };
  }
}

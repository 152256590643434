import {
  Component,
  OnInit,
  ChangeDetectorRef,
  ViewEncapsulation,
  ViewChild,
  Input
} from '@angular/core';
import { MediaMatcher } from '@angular/cdk/layout';
import { NavigationEnd, Router } from '@angular/router';
import {
  sideNavAnimation,
  sideNavContainerAnimation
} from './sidenav.animation';
import { MatSidenav } from '@angular/material/sidenav';
import { environment } from '@env/environment';
import { SwUpdate } from '@angular/service-worker';
import { UtilsService } from './core/utils.service';
import { AuthService } from './core/auth/auth.service';
import { MatDialogRef, MatDialog } from '@angular/material';
import { IlMessageDialogComponent } from './il-message-dialog/il-message-dialog.component';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
  animations: [sideNavAnimation, sideNavContainerAnimation]
})
export class AppComponent implements OnInit {
  @ViewChild('snav', { static: false }) sidenav: MatSidenav;

  mobileQuery: MediaQueryList;
  url: string;
  isInLivePage: boolean;
  isOpen: boolean = false;
  public units: any[] = [];
  selectedUnit: string;
  selectedConsole: string;
  selectedEquipment: string;
  oUrl: string;
  features: any[];
  isOperator: boolean;
  role: string;
  name: string;

  private _mobileQueryListener: () => void;
  licenseMessage: any;
  isValid: any;
  lnsURL=environment.lnsURL;

  constructor(
    changeDetectorRef: ChangeDetectorRef,
    media: MediaMatcher,
    private router: Router,
    private swUpdate: SwUpdate,
    private utils: UtilsService,
    private authService: AuthService,
    private dialog: MatDialog
  ) {
    this.mobileQuery = media.matchMedia('(max-width: 600px)');
    this._mobileQueryListener = () => changeDetectorRef.detectChanges();
    this.mobileQuery.addListener(this._mobileQueryListener);
    console.log(this.router.config);
    
  }

  ngOnInit(): void {
  // this.refresh();
    this.isValid = localStorage.getItem("backToken");
    console.log("backtoken",this.isValid);
    /* Check if any update available in the application */
    if (this.swUpdate.isEnabled) {
      this.swUpdate.available.subscribe(() => {
        if (confirm('New version available. Load New Version?')) {
          window.location.reload();
          if (localStorage.getItem('user')) {
            localStorage.removeItem('user');
            this.router.navigate(['auth/login']);
          }
        }
      });
    }


    this.features = environment['features'];

    this.mobileQuery.removeListener(this._mobileQueryListener);

    this.router.events.subscribe(val => {
      if (val instanceof NavigationEnd) {
        this.getUrl();
        this.isUserAuthenticated();
      }
    });
  }

  goToLanding() {
    this.router.navigate(['landing-screen']);
  }
  /* get the current page URL */
  getUrl() {
    this.url = this.router.url;

    this.oUrl = this.url.split('/')[1];

    if (this.units.length === 0 && this.url !== '/auth/login' && this.url !== '/error') {
      this.getAllConsoles();
    }

    /* if (this.isUserAuthenticated() && this.units.length === 0) {
      this.getLicence();
    } */

    if (this.units.length > 0) {
      this.getFdhdrTags();
    }

    if (this.url !== '/auth/login' && this.url !== '/error') {
      this.role = this.authService.getLoggedUserRole();
      this.name = this.authService.getUsername();
      this.isOperator = this.role !== 'operator' ? false : true;
    }
    this.setNavbar(this.url);
  }

  isUserAuthenticated() {
    return !this.authService.isSessionExpired();
  }

  /* Method to logout */
  logout() {
    if(this.isValid){
      console.log("isBackToken",this.isValid);
      localStorage.removeItem('backToken');
    localStorage.removeItem('user');
    localStorage.removeItem('refreshToken');
    window.location.href=environment.lnsURL;
    }else{
      localStorage.removeItem('user');
      localStorage.removeItem('refreshToken');
    this.units = [];
    this.name = null;
    this.role = null;
    this.licenseMessage = null;
    this.router.navigate(['auth/login']);
  }
  }

  /* Method which returns animation trigger if in desktop else returns null */
  provideAnimationTrigger() {
    if (this.mobileQuery.matches) return null;

    return this.isOpen ? 'open' : 'closed';
  }

  /* Method which toggles the menu only in mobile */
  toggleSideNav() {
    this.sidenav.toggle();
  }

  /* Method to navigate to external target */
  goToExternalTarget() {
    this.router.navigate(['external-target']);
  }

  /* Method which will be used to navigate to equipment */
  goToEquipment(event, u, c, e, et) {
    event.stopPropagation();
    // this.router.navigate(['equipment', u, c, `${e} (${et})`]);
    // changed on 28/09/2019 based on changes made on master table

    this.router.navigate(['equipment', u, c, e]);
  }

  /* Method used to navigate to console */
  goToConsole(u, c) {
    this.router.navigate(['console', u, c]);
  }

  /* Method which will navigate to the feature */
  goToFeature(f) {
    /* Extract the equipment name from the URL
     * as the selected equipment having only equipment name */
    const u = this.router.url.split('/');

    if (u[1] === 'console' || u[1] === 'unit') {
      return;
    } else {
      this.router.navigate([
        f,
        this.selectedUnit,
        this.selectedConsole,
        decodeURI(u[4])
      ]);
    }
  }

  /* Method to navigate to the unit */
  goToUnit(unit) {
    this.router.navigate(['unit', unit]);
  }



  /* Method to set selected equipment/console/unit on refresh */
  setNavbar(url) {
    const u = url.split('/');

    if (u[1] === 'unit' && u.length === 2) {

      if (this.units.length > 0)
        this.goToUnit(this.units[0]['unit_name']);
    }

    if (u[1] === 'console' && u.length === 2) {

      if (this.units.length > 0)

        this.goToConsole(this.units[0]['unit_name'], this.units[0]['consoles'][0]['console_name']);
    }
    if (
      u[1] === 'equipment' ||
      u[1] === 'deviation-tracker' ||
      u[1] === 'dynamic-benchmarking' ||
      u[1] === 'equipment-health' ||
      u[1] === 'performance-tracker' ||
      u[1] === 'instrument-drift'
    ) {
      this.selectedConsole = decodeURI(u[3]);
      this.selectedEquipment = this.utils.extractEquipmentTag(decodeURI(u[4]));
      this.selectedUnit = decodeURI(u[2]);
    }

    if (u[1] === 'console') {
      this.selectedConsole = decodeURI(u[3]);
      this.selectedUnit = decodeURI(u[2]);
      this.selectedEquipment = null;
    }

    if (u[1] === 'unit' && u.length > 2) {
      this.selectedConsole = null;
      this.selectedUnit = decodeURI(u[2]);
    }
  }

  /* Method which calls api to get all the consoles */
  getAllConsoles() {
    this.utils.getAllConsoles().subscribe(
      data => {
        this.units = data;
        const u = this.router.url;

        this.setNavbar(this.url);

        this.getFdhdrTags();

        const c = this.units[0]['consoles'];
        if (c.length > 0) {
          c.forEach(e => {
            e['expanded'] = true;
          });
        }
      },
      err => {
        console.log(err);
      }
    );
  }

  /* Method to toggle the collapse */
  toggle(u) {
    u['expanded'] = !u['expanded'];

    this.units[0]['consoles'].forEach(e => {
      if (e['console_name'] !== u['console_name']) {
        e['expanded'] = true;
      }
    });
  }

  /* Method to navigate to change password */
  goToChangePassword() {
    this.router.navigate(['account/change-password']);
  }

  /* Method to get the equipment FDHDR value, 
    currently using hot console 1 API
    This can be merged with consoles API */
  getFdhdrTags() {
    this.utils.getFdhdr().subscribe(data => {
      this.units[0]['consoles'][0]['equipments']
        .map(e => {
          let fdhdr = data['graph']['equipments']
            .filter(k => k['equipment_name'] === e['equipment_tag_name']).pop();
          fdhdr['equipment_name'] = e['equipment_name'];
          Object.assign(e, { ...fdhdr, fdhdr });
        });
    });

  }

  /* Method which opens inferential logic message popup */
  openMessage(e, equipment) {
    // restrict navigating to equipment screen
    e.stopPropagation();

    this.utils.getILMessage(equipment).subscribe(data => {
      const dialogRef: MatDialogRef<IlMessageDialogComponent> = this.dialog.open(
        IlMessageDialogComponent,
        {
          panelClass: 'view-il-dialog',
          disableClose: true,
          hasBackdrop: true,
          width: '50%',
          data: data
        }
      );
    }, err => {
      console.log(err);

    })
  }
  // refresh(): void {
  //   setTimeout(() => {
  //   console.log("refresh",localStorage.getItem("refreshToken"));
  //   if(localStorage.getItem("refreshToken")==="false"){
  //   window.location.reload();
  //   localStorage.setItem("refreshToken","true")
  //   }
  //   if(localStorage.getItem("refreshToken")==null){
  //     window.location.reload();
  //     console.log("console null");
  //     localStorage.setItem("refreshToken","false")
  //     }},2000);
  // }

  /* Method which gets the info about the licence validity */
  getLicence() {
    this.utils.getLicenceValidity().subscribe(data => {
      if (data['notify_status'])
        this.licenseMessage = data['message'];
      else
        return;
    })
  }

  /* Method which closes the license validity message */
  closeLicenseDialog() {
    this.licenseMessage = null;
  }

  cleanUp(){
    localStorage.removeItem('user');
    localStorage.removeItem('backToken');
    localStorage.removeItem('refreshToken');
  }
   }


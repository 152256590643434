import { Component, OnInit } from '@angular/core';
import { environment } from '@env/environment';
import { Router } from '@angular/router';


@Component({
  selector: 'app-error',
  templateUrl: './error.component.html',
  styleUrls: ['./error.component.css']
})
export class ErrorComponent implements OnInit {

  errorCauses: any[];

  constructor(readonly router: Router) { }

  ngOnInit() {

    this.errorCauses = environment['errorCauses'];
  }

  goToLogin() {

    // remove if any user login in local storage
    if (typeof localStorage.getItem('user') !== 'undefined')
      localStorage.removeItem('user');

    this.router.navigate(['auth/login']);
  }

}

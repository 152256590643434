import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from './core/guards/auth.guard';
import { UnitGuard } from './core/guards/unit.guard';
import { ConfigurationGuard } from './core/guards/configuration.guard';
import { ErrorComponent } from './error/error/error.component';

const routes: Routes = [
  {
    path: 'equipment',
    loadChildren: () =>
      import('@app/routes/equipment/equipment.module').then(
        m => m.EquipmentModule
      ),
    canActivate: [AuthGuard]
  },
  {
    path: 'console',
    loadChildren: () =>
      import('@app/routes/console/console.module').then(m => m.ConsoleModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'unit',
    loadChildren: () =>
      import('@app/routes/unit/unit.module').then(m => m.UnitModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'landing-screen',
    loadChildren: () =>
      import('@app/routes/landing-screen/landing-screen.module').then(m => m.LandingScreenModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'dynamic-benchmarking',
    loadChildren: () =>
      import(
        '@app/routes/dynamic-benchmarking/dynamic-benchmarking.module'
      ).then(m => m.DynamicBenchmarkingModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'equipment-health',
    loadChildren: () =>
      import('@app/routes/equipment-health/equipment-health.module').then(
        m => m.EquipmentHealthModule
      ),
    canActivate: [AuthGuard]
  },
  {
    path: 'instrument-drift',
    loadChildren: () =>
      import('@app/routes/instrument-drift/instrument-drift.module').then(
        m => m.InstrumentDriftModule
      ),
    canActivate: [AuthGuard]
  },
  {
    path: 'deviation-tracker',
    loadChildren: () =>
      import('@app/routes/deviation-tracker/deviation-tracker.module').then(
        m => m.DeviationTrackerModule
      ),
    canActivate: [AuthGuard]
  },
  {
    path: 'performance-tracker',
    loadChildren: () =>
      import('@app/routes/performance-tracker/performance-tracker.module').then(
        m => m.PerformanceTrackerModule
      ),
    canActivate: [AuthGuard]
  },
  {
    path: 'configuration',
    loadChildren: () =>
      import('@app/routes/configuration/configuration.module').then(
        m => m.ConfigurationModule
      ),
    canActivate: [AuthGuard, ConfigurationGuard]
  },
  {
    path: 'external-target',
    loadChildren: () =>
      import('@app/routes/external-target/external-target.module').then(
        m => m.ExternalTargetModule
      ),
    canActivate: [AuthGuard, ConfigurationGuard]
  },
  {
    path: 'auth',
    loadChildren: () =>
      import('@app/routes/account/account.module').then(m => m.AccountModule)
  },
   {
    path: 'account',
    loadChildren: () =>
      import('@app/routes/account-settings/account-settings.module').then(m => m.AccountSettingsModule)
  },
  {
    path: 'gap-analysis',
    loadChildren: () =>
      import('@app/routes/gap-analysis/gap-analysis.module').then(
        m => m.GapAnalysisModule
      ),
    canActivate: [AuthGuard]
  },
  {
    path: 'reports',
    loadChildren: () =>
      import('@app/routes/reports/reports.module').then(
        m => m.ReportsModule
      ),
    canActivate: [AuthGuard]
  },
  {
    path: '',
    redirectTo: 'auth',
    pathMatch: 'full'
  },
  {
    path: 'error',
    component: ErrorComponent
  },
  {
    path: '**',
    redirectTo: 'auth'
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true })],
  exports: [RouterModule]
})
export class AppRoutingModule { }

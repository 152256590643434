import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'icolor'
})
export class IcolorPipe implements PipeTransform {

  transform(value: any): any {
    return value === 4
      ? '#FF0000'
      : value === 3
        ? '#FFC813'
        : value === 2
          ? '#0667BB'
          : value === 1 ? '#7FBB06'
            : '#444C5E';
  }

}
